<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0">Default</h6>
                </div>
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4">
                <div class="card-header">
                    <h3 class="mb-0">Welcome</h3>
                </div>
                <div class="card-body">
                    Dashboard
                </div>
            </div>
        </div>
    </div>
</template>
<script>
	import BaseProgress from '@/components/BaseProgress';
	import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
	import StatsCard from '@/components/Cards/StatsCard';
	import Api from '@/helpers/api';
	import authService from '@/services/auth.service';

	export default {
		components: {
			BaseProgress,
			RouteBreadCrumb,
			StatsCard
		},
	};
</script>
<style></style>
